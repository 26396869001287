
<template>
    <div class="swiper-container swiper2">
      <div class="swiper-wrapper" v-if="lbt.length">
        <!-- <div class="swiper-slide" style="background-color: bl;width: 1280px;height: 460px;margin: auto;position: relative;" >
          
          <img :src="a" alt="" style="width:580px;height: 370px;">
        <div style="width: 580px;height: 50px;background-color: rgb(44, 42, 42);opacity: 0.8;position: absolute;bottom: 90px;z-index: 999;">
         
        </div>
        <div style="padding-left:20px;box-sizing: border-box;width: 580px;height: 50px;opacity: 1;position: absolute;bottom: 90px;z-index: 999;line-height: 50px;color: #FFF;">
          董旭出席2023第一届中国酒类物...
        </div>
        </div>
        <div class="swiper-slide" style="background-color: bl;width: 1280px;height: 460px;margin: auto;position: relative;" >
          
          <img :src="b" alt="" style="width:580px;height: 370px;">
        <div style="width: 580px;height: 50px;background-color: rgb(44, 42, 42);opacity: 0.8;position: absolute;bottom: 90px;z-index: 999;">
         
        </div>
        <div style="padding-left:20px;box-sizing: border-box;width: 580px;height: 50px;opacity: 1;position: absolute;bottom: 90px;z-index: 999;line-height: 50px;color: #FFF;">
          董旭出席2023第一届中国酒类物...
        </div>
        
        </div>

        <div class="swiper-slide" style="background-color: bl;width: 1280px;height: 460px;margin: auto;position: relative;" >
          
          <img :src="c" alt="" style="width:580px;height: 370px;">
        <div style="width: 580px;height: 50px;background-color: rgb(44, 42, 42);opacity: 0.8;position: absolute;bottom: 90px;z-index: 999;">
         
        </div>
        <div style="padding-left:20px;box-sizing: border-box;width: 580px;height: 50px;opacity: 1;position: absolute;bottom: 90px;z-index: 999;line-height: 50px;color: #FFF;">
          董旭出席2023第一届中国酒类物...
        </div>
        
        </div> -->
        <div class="swiper-slide" style="background-color: bl;width: 690px;height:340px;margin: auto;position: relative;" v-for="item in lbt" :key="item.id">
          <a :href="item.a">
         <img :src="item.url" alt="" style="width:690px;height: 340px;">
         <div style="width: 690px;height: 30px;background-color: rgba(0,0,0,0.6);opacity: 0.8;position: absolute;bottom: 0px;z-index: 999;">
        
       </div>
       <div style="padding-left:20px;box-sizing: border-box;width: 690px;height: 30px;opacity: 1;position: absolute;bottom: 0px;z-index: 999;line-height: 30px;color: #FFF;">
        {{ item.text }}
       </div>
      </a>
       </div>
   
        <!-- <slot name="slid"></slot> -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
   
      <!-- 如果需要导航按钮 -->
      
      <div class="swiper-button-prev">
        
      </div>
      <!-- <div   class="box" style="left: 10px;">
        <div style="width: 100%;height: 100%;" class="box1">

      </div></div> -->
      <div class="swiper-button-next"></div>
      <!-- <div  class="box" style="right: 10px;">
        <div style="width: 100%;height: 100%;" class="box1">

      </div></div> -->
      <!-- <div style="width: 55px;height: 482px;background-color: rgb(255, 249, 249); position: absolute;top: 0;z-index: 90;right: 0;"></div>
      <div style="width: 55px;height: 482px;background-color: rgb(255, 249, 249); position: absolute;top: 0;z-index: 90;left: 0;"></div> -->
      <!-- 如果需要滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </div>
  </template>
   
  <script>
  import "swiper/dist/js/swiper";
  import "swiper/dist/css/swiper.css";
  import Swiper from "swiper";
  export default {
    name:'SwiPer',
    props: {},
    data() {
      return {
        lbt:['https://ess.leju.com/house/photo/42-1-5iZpP1e5nePr…dam2LspdOFLsx9pCjcZw4nrGz9M6TexgYV3esx872nfVM.jpg'],
    
      };
    },
    methods: {
      async getsdlbt(){
      const res=await this.$store.dispatch('getfclbt');
      this.lbt=res
    },
    //   async getlbt(){
    //   const res=await this.$store.dispatch('getwlgslbt');
    //   this.lbt=res
    //   this.a=res[0].url
    //   this.b=res[1].url
    //   this.c=res[2].url
    // },
    },
    components: {},
    mounted(){
     this.getsdlbt()
    },
    updated() {
     
      new Swiper(".swiper2", {
      
        direction: "horizontal", // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 2500, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
          bulletActiveClass: 'my-bullet-active'
        },
    
        loop: true, // 循环模式选项
   
        // 如果需要分页器
        pagination: {
          type:'fraction',
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
          // color:'#fff'
        },
   
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
   
        // 如果需要滚动条
        scrollbar: {
          // el: ".swiper-scrollbar",
        },
        fadeEffect: {
    crossFade: true,
  },
      });
  
    },
  };
  </script>
   
  <style scoped lang="less">
 .my-bullet-active{
    background: #ce3333;
    background: rgba(255,255,255,.5)!important;
    /*opacity: 0.6;*/
 }
  .swiper-pagination {
    width: 100px;
    position: absolute;
    bottom: -0.1rem;
    right: 1rem;
    text-align: right !important;
  }
   
  .swiper-container ::v-deep .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  .swiper-container ::v-deep .swiper-button-prev{
background-image: url('https://s1.ax1x.com/2023/06/27/pCalRgK.png');
background-size: 120%;
position: absolute;
left: 15px;
// left: -30px;
z-index: 999;
  }
  .swiper-container ::v-deep .swiper-button-next{
    // background-color: #ce3333;
    // opacity: 0.8;
    // width: 50px;
    // height: 100px;
background-image: url('https://s1.ax1x.com/2023/06/27/pCalTUA.png');
background-size: 120%;
z-index: 999;
right: 15px;
  }
  .swiper-container{
    position: relative;
    width: 590px;
    height: 340px;
    // overflow: visible;
    // padding: 0 50px;
  }
  .swiper-container ::v-deep .swiper-pagination{
    position: absolute;
    left: 360px;
    bottom: 1px;
    width: 200px;
  }
  .swiper-container ::v-deep .swiper-pagination-bullet{
    background-color: #fcf9f9;
    opacity: 1;
    width: 13px;
    height: 13px;
    // border-radius: 30px;
  }
  .swiper-container ::v-deep .swiper-pagination-bullet-active{
    background-color: rgb(163, 28, 97);
  }
  .box{
    width: 40px;height: 80px;background-color: rgb(37, 37, 37);position:absolute;top: 40%;z-index: 888;
   
    opacity: 0.5;
  }
  .box1{
    // background-color: #fff;
  }
  .box:hover .box1{
    // background-color: #eee8e8;
    opacity: 0.5;
     background-color: #fff;
  }
  // .swiper-pagination
  .swiper-button-prev:hover+.box>.box1{
    opacity: 0.5;
     background-color: #fff;
  }
  .swiper-button-next:hover+.box>.box1{
    opacity: 0.5;
     background-color: #fff;
  }

  .swiper-container ::v-deep .swiper-pagination-current{
    color: #01ADFF;
    font-size: 22px;
  }
  .swiper-container ::v-deep .swiper-pagination-total{
    color: #fff;
  }
  .swiper-container ::v-deep .swiper-pagination-fraction{
    color: #fff;
  }
  </style>