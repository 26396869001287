<template>
  <div
    style="
      height: 100%;
      width: 100%;

      margin: 0 auto;
      margin: auto;
      font-family: '微软雅黑';
      position: relative;
    "
  >
    <div
      style="
        width: 1200px;
        height: 100px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div
        style="
          width: 300px;
          height: 100px;
          background-image: url('https://s1.ax1x.com/2023/07/03/pCr5M6g.png');
          background-position: center;
          background-size: 70%;
          background-repeat: no-repeat;
        "
      ></div>
      <!-- <div
        style="
          width: 617px;
          height: 42px;
          background-image: url('http://src.leju.com/imp/imp/deal/23/41/f/f9885c871bfe2b38b9505be822b_p24_mk24.svg');
          background-position: center;
          background-size: 100%;
        "
      ></div> -->
    </div>
    <div style="width: 100%; height: 50px; background-color: #c72128">
      <ul
        class="nav"
        style="
        position: relative;
          width: 1200px;
          height: 50px;
          margin: auto;
          /* background-color: yellow; */
        "
      >
        <li><a href="http://www.fangchan.com/">首页</a></li>
        <li>
          <a href="http://zjt.hainan.gov.cn/szjt/ywdt/yaowen.shtml" title="要闻动态">要闻动态</a>
        </li>
        <li>
          <a href="http://zjt.hainan.gov.cn/szjt/zccc/newxxgk_index.shtml" title="信息公开" target="_blank"
            >信息公开</a
          >
        </li>
        <li>
          <a href="http://zjt.hainan.gov.cn/szjt/jdhy/jdhy.shtml" title="解读回应">解读回应</a>
        </li>
        <li>
          <a href="https://www.hainan.gov.cn/" title="政务服务">政务服务</a>
        </li>
        <li>
          <a href="https://www.hainan.gov.cn/" title="数据开放"
            >数据开放</a
          >
        </li>
        <li>
          <a href="http://zjt.hainan.gov.cn/szjt/hdjl/hudong.shtml" title="交流互动" target="_blank"
            >交流互动</a
          >
        </li>
        <li class="last"  style="position: relative;"  @mouseenter="show=0">
          <a href="https://wssp.hainan.gov.cn/hnwt/home" target="_self" title="办事服务">办事服务</a>
          
        </li><div style="width: 1200px;height: 230px;position: absolute;z-index: 99;top: 50px;right: 0px;display: flex;padding: 20px;box-sizing: border-box;box-shadow: 0 0 10px #ccc;background-color: #fff;" v-if="show==100" @mouseleave="show=1">
          <div style="width: 706px;height: 100%;">
          <div style="width: 100%;height: 41px;font-size: 20px;color: #333333;border-bottom: 1px solid #ccc;text-align: start;line-height: 41px;">
          
          协会介绍
          </div>
          <div class="utop">
            <a href="http://www.fangchan.com/prefecture/society/2010/1124/2330.html">
               <div>协会介绍</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/xhld/6548535440289632908.html">
              <div>协会领导</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/5892971359486945700/2019/0604/6541500828908589514.html">
              <div>会员管理办法</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/5892971359486945700/2022/0617/6943393449404338713.html">
              <div>自律公约</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/5892971359486945700/2022/0617/6943393449404338713.html">
              <div>协会章程</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/5892971359486945700/2021/0623/6813347601296200172.html">
              <div>监事会</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/8th/6541512379795312826.html">
              <div>会费管理办法</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/5892971359486945700/2022/0617/6943393449404338713.html">
              <div>道德准则</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/5892971359486945700/2019/0103/6486511873528500233.html">
              <div>理事会</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/ms/2010/1125/2587.html">
              <div>秘书处</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/zjk/jishu/6120430472716948023.html">
              <div>专家委员会</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/bzhgl/2019/0505/6530691741383987292.html">
              <div>标准化委员会</div>
            </a>
          </div>
          </div>
          <div style="width: 350px;height: 100%;margin-left: 90px;">
            <div style="width: 100%;height: 41px;font-size: 20px;color: #333333;border-bottom: 1px solid #ccc;text-align: start;line-height: 41px;">
              联系协会
          </div>
          <div class="utop">
            <a href="http://www.fangchan.com/prefecture/ms/2011/0224/152722.html">
               <div>会员服务和地方联络部</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/ms/2011/0223/152389.html">
              <div>综合部</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/ms/2011/0224/152765.html">
              <div>合作发展部</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/ms/2011/0224/152775.html">
              <div>财务部</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/ms/2011/0224/152770.html">
              <div>研究宣传培训部</div>
            </a>
            <a href="http://www.fangchan.com/prefecture/7/2011/0831/181724.html">
              <div>分支机构</div>
            </a>
            
          </div>
          </div>
          </div>
      </ul>
    </div>
    <div
      style="
        width: 1200px;
        margin: auto;
        display: flex;
        margin-top: 20px;
        height: 355px;
        /* background-color: aqua; */
      "
    >
      <div style="width: 590px; height: 340px">
        <swiper></swiper>
      </div>
      <div
        style="
          width: 590px;
          height: 342px;
          background-color: rgb(255, 255, 255);
          margin-left: 20px;
        "
      >
        <ul
          style="
            width: 100%;
            height: 54px;

            position: relative;
            align-items: center;
            display: flex;
            padding-left: 40px;
            box-sizing: border-box;
            background-image: url('https://ess.leju.com/house/photo/42-1-t2Eg5H7VTG5SopeNiSxBu5eXke3bv91WGxpB30cPQTMKaIvWdCHe3e8yx6tpCLhBHzKfnzLCmtIEgPXo.png');
            background-position: 0 10px;
            background-repeat: no-repeat;
            border-bottom: 2px solid #eeeeee;
            /* background: url(https://ess.leju.com/house/photo/42-1-t2Eg5H7VTG5S…B30cPQTMKaIvWdCHe3e8yx6tpCLhBHzKfnzLCmtIEgPXo.png) no-repeat 0 10px; */
            padding-left: 40px;
            background-size: 31px 34px;
          "
        >
          <li
            style="
              font-size: 20px;
              height: 54px;
              line-height: 54px;
              color: #c72128;
              font-weight: bold;
            "
          >
            最新动态
          </li>
          <a href="">
            <li style="font-size: 16px; margin-left: 20px; color: #999">
              领导动态
            </li>
          </a>
          <a href="http://zjt.hainan.gov.cn/">
            <li
              style="
                position: absolute;
                right: 0;
                background-image: url('https://ess.leju.com/house/photo/42-1-QpdANKx9SZ3NiaXlSqmPB0HSmPFxEtlYMZHm9Wass0SLJycTSWhZM723e6VguFOytEMt5plVCN602uNK.png');
                background-size: 10px;
                background-position: center right;
                background-repeat: no-repeat;
                padding-right: 15px;
                font-size: 12px;
                bottom: 15px;
              "
            >
              更多
            </li>
          </a>
          <div
            style="
              width: 100px;
              height: 4px;
              position: absolute;
              background-color: #89181d;
              bottom: -4px;
              left: 32px;
            "
          ></div>
        </ul>
        <ul
          style="
            width: 590px;
            height: 290px;
            /* background-color: rgb(114, 150, 150); */
          "
          class="u4"
        >
          <a :href="fxgz.a1">
            <li>
             {{ fxgz.text1 }}
            </li>
          </a>
          <a :href="fxgz.a2">
            <li>
             {{ fxgz.text2 }}
            </li>
          </a>
          <a :href="fxgz.a3">
            <li>
             {{ fxgz.text3 }}
            </li>
          </a>
          <a :href="fxgz.a4">
            <li>
             {{ fxgz.text4 }}
            </li>
          </a>
          <a :href="fxgz.a5">
            <li>
             {{ fxgz.text5 }}
            </li>
          </a>
          <a :href="fxgz.a6">
            <li>
             {{ fxgz.text6 }}
            </li>
          </a>
          <a :href="fxgz.a7">
            <li>
             {{ fxgz.text7 }}
            </li>
          </a>
          <a :href="fxgz.a8">
            <li>
             {{ fxgz.text8 }}
            </li>
          </a>
        </ul>
      </div>
    </div>
    <div
      style="
        width: 100%;
        height: 155px;
        background-color: #eeeeee;
        overflow: hidden;
      "
    >
      <ul
        style="width: 1200px; margin: auto; height: 80px; margin-top: 35px"
        class="u5"
      >
        <a :href="zxt.a1">
          <li>
            <img
              :src="zxt.url1"
              alt=""
              style="width: 285px; height: 80px"
            />
          </li>
        </a>
        <a :href="zxt.a2">
          <li>
            <img
              :src="zxt.url2"
              alt=""
              style="width: 285px; height: 80px"
            />
          </li>
        </a>
        <a :href="zxt.a3">
          <li>
            <img
              :src="zxt.url3"
              alt=""
              style="width: 285px; height: 80px"
            />
          </li>
        </a>
        <a :href="zxt.a4">
          <li>
            <img
              :src="zxt.url4"
              alt=""
              style="width: 285px; height: 80px"
            />
          </li>
        </a>
      </ul>
    </div>
    <div style="width: 1200px; height: 470px; margin: auto; position: relative">
      <ul class="u6">
        <li
          style="
            font-size: 20px;
            color: #c72128;
            font-weight: bold;
            line-height: 50px;
          "
        >
          热点
        </li>
        <a href="">
          <li>访谈</li>
        </a>
        <a href="">
          <li>服务</li>
        </a>
        <a href="">
          <li>互动</li>
        </a>
        <li style="width: 400px; height: 40px; position: absolute; right: 10px">
          <input
          v-model="value"
            type="text"
            style="
              width: 400px;
              height: 40px;
              border-radius: 20px;
              border: 1px solid #ccc;
              padding-left: 20px;
              box-sizing: border-box;
            "
            class="search_input"
            placeholder="查咨询"
          />
          <span
            style="
              width: 24px;
              height: 26px;
              display: inline-block;
              background-image: url('https://ess.leju.com/house/photo/42-1-0naJ6VRLXeHNhXJeEOJDXdVL3o0RMN2SNebZ4QpW6hwFiLxZcus8pJ1uFwwWu9hRjRuQIeXvfZKbriGR.png');
              background-position: center;
              background-size: 100%;
              background-repeat: no-repeat;
              top: 17px;
              right: 17px;
              position: absolute;
              
            "
            @click="gobd"
          ></span>
        </li>
      </ul>
      <div
        style="
          width: 1200px;
          height: 2px;
          background-color: #eeeeee;
          margin-top: 5px;
          position: relative;
        "
      >
        <div
          style="
            width: 100px;
            height: 4px;
            background-color: #89181d;
            position: absolute;
            bottom: -1px;
            left: 60px;
          "
        ></div>
      </div>
      <div
        style="width: 1200px; height: 385px; margin-top: 30px; display: flex"
      >
        <div style="width: 780px; height: 385px">
          <div style="width: 780px; height: 170px; display: flex">
            <div style="width: 290px; height: 170px">
              <a :href="zx.a">
                <img
                  :src="zx.url"
                  alt=""
                  style="width: 290px; height: 170px"
                />
              </a>
            </div>
            <div style="width: 465px; height: 170px; margin-left: 20px">
              <h2 class="h1">
                <a :href="zx.a">{{ zx.title }}</a>
              </h2>
              <p style="margin-top: 20px; margin-bottom: 20px" class="p1">
                {{ zx.text }}
              </p>
              <a href=""
                ><span
                  style="
                    width: 50px;
                    height: 22px;
                    border: 1px solid #c72128;
                    border-radius: 6px;
                    color: #c72128;
                    font-size: 12px;
                    padding: 4px 10px;
                    margin-right: 10px;
                  "
                  >{{ zx.types }}</span
                ></a
              >
              <span style="font-size: 12px; color: #333; padding: 4px 10px"
                >{{ zx.times }}</span
              >
            </div>
          </div>
          <div
            style="
              width: 780px;
              height: 135px;
              background-color: #fffefe;
              margin-top: 50px;
            "
          >
            <h2 class="h3">
              <a :href="zx.a1">{{ zx.title1 }}</a>
            </h2>
            <p
              style="
                line-height: 1.875rem;
                margin-top: 10px;
                font-size: 0.875rem;
                color: #999999;
              "
            >
              {{ zx.text1 }}
            </p>
            <p style="margin-top: 15px">
              <a href="/news/6/index.html"
                ><span
                  style="
                    border: 1px solid #c72128;
                    border-radius: 6px;
                    color: #c72128;
                    font-size: 12px;
                    padding: 4px 10px;
                    margin-right: 10px;
                  "
                  >{{ zx.types }}</span
                ></a
              ><span style="font-size: 12px; color: #333; padding: 4px 10px"
                >{{ zx.times }}</span
              >
            </p>
          </div>
        </div>
        <div
          style="
            width: 400px;
            height: 385px;

            margin-left: 20px;
          "
        >
          <div
            style="
              width: 400px;
              height: 32px;
              background-image: url('https://ess.leju.com/house/photo/42-1-xRG0TMVa7kmrexDTQlr6Chye2JwYpCoP1rq8oGbXBxL7JRtSTV01HJChvg4fmSrArfinD7UXBOMleP1e.png');
              background-size: 120px 32px;
              background-repeat: no-repeat;
              width: 240px;
              height: 32px;
              line-height: 52px;
              font-size: 12px;
              color: #999;
              margin-bottom: 16px;
              padding-left: 140px;
            "
          >
            <span> </span>
            <a
              href="http://zjt.hainan.gov.cn/"
              style="
                float: right;
                font-size: 12px;
                background: url(https://ess.leju.com/house/photo/42-1-QpdANKx9SZ3NiaXlSqmPB0HSmPFxEtlYMZHm9Wass0SLJycTSWhZM723e6VguFOytEMt5plVCN602uNK.png) no-repeat 30px 21px;
                background-size: 10px 10px;
                padding-right: 20px;
                background-repeat: no-repeat;
              "
              >更多</a
            >
          </div>
          <ul
            class="u7"
            style="
              background-color: #eeeeee;
              width: 400px;
              height: 332px;
              /* overflow: hidden; */
              padding: 20px;
              box-sizing: border-box;
            "
          >
            <li>
              <span
                style="
                  background: url(https://ess.leju.com/house/photo/42-1-6awGT2tFdrOpN33CmEwhndL1D8u23qgdSK1NWJIoN9Hs6vhNKU8N3iUpjAmwTK0qqXEaYBSUSyh16ovX.png) no-repeat;
                  background-size: 15px 15px;
                  width: 400px;
                  padding-left: 25px;
                  display: block;
                  color: #999999;
                  font-size: 12px;
                  height: 16px;
                "
                ></span
              >
              <p
                style="
                  padding-left: 17px;
                  border-left: 1px solid rgba(199, 33, 40, 0.1);
                  margin-left: 8px;
                  line-height: 24px;
                  margin-top: 10px;
                "
              >
                <a
                  :href="jszx.a1"
                  style="width: 400px; white-space: normal"
                  class="a1"
                  ><span
                    >{{ jszx.text1 }}</span
                  ></a
                >
              </p>
            </li>
            <li>
              <span
                style="
                  background: url(https://ess.leju.com/house/photo/42-1-6awGT2tFdrOpN33CmEwhndL1D8u23qgdSK1NWJIoN9Hs6vhNKU8N3iUpjAmwTK0qqXEaYBSUSyh16ovX.png) no-repeat;
                  background-size: 15px 15px;
                  width: 400px;
                  padding-left: 25px;
                  display: block;
                  color: #999999;
                  font-size: 12px;
                  height: 16px;
                "
                ></span
              >
              <p
                style="
                  padding-left: 17px;
                  border-left: 1px solid rgba(199, 33, 40, 0.1);
                  margin-left: 8px;
                  line-height: 24px;
                  margin-top: 10px;
                "
              >
                <a
                  :href="zx.a2"
                  style="width: 400px; white-space: normal"
                  class="a1"
                  ><span
                    >{{ zx.text2 }}</span
                  ></a
                >
              </p>
            </li>
            <li>
              <span
                style="
                  background: url(https://ess.leju.com/house/photo/42-1-6awGT2tFdrOpN33CmEwhndL1D8u23qgdSK1NWJIoN9Hs6vhNKU8N3iUpjAmwTK0qqXEaYBSUSyh16ovX.png) no-repeat;
                  background-size: 15px 15px;
                  width: 400px;
                  padding-left: 25px;
                  display: block;
                  color: #999999;
                  font-size: 12px;
                  height: 16px;
                "
                ></span
              >
              <p
                style="
                  padding-left: 17px;
                  border-left: 1px solid rgba(199, 33, 40, 0.1);
                  margin-left: 8px;
                  line-height: 24px;
                  margin-top: 10px;
                "
              >
                <a
                  :href="zx.a3"
                  style="width: 400px; white-space: normal"
                  class="a1"
                  ><span
                    >{{ zx.text3 }}</span
                  ></a
                >
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      style="
        width: 1200px;
        height: 520px;

        margin: auto;
        display: flex;
      "
    >
      <div style="width: 780px; height: 488px">
        <div style="width: 780px; height: 135px; background-color: #fffefe">
          <h2 class="h3">
            <a :href="zx.a2"
              >{{ zx.title2 }}</a
            >
          </h2>
          <p
            style="
              line-height: 1.875rem;
              margin-top: 10px;
              font-size: 0.875rem;
              color: #999999;
            "
          >
            {{ zx.text2 }}
          </p>
          <p style="margin-top: 15px">
            <a href="/news/6/index.html"
              ><span
                style="
                  border: 1px solid #c72128;
                  border-radius: 6px;
                  color: #c72128;
                  font-size: 12px;
                  padding: 4px 10px;
                  margin-right: 10px;
                "
                >{{ zx.type2 }}</span
              ></a
            ><span style="font-size: 12px; color: #333; padding: 4px 10px"
              >{{ zx.time2 }}</span
            >
          </p>
        </div>

        <div
          style="
            width: 780px;
            height: 135px;
            background-color: #fffefe;
            margin-top: 30px;
          "
        >
          <h2 class="h3">
            <a :href="zx.a3">{{ zx.title3 }}</a>
          </h2>
          <p
            style="
              line-height: 1.875rem;
              margin-top: 10px;
              font-size: 0.875rem;
              color: #999999;
            "
          >
            {{ zx.text3 }}
          </p>
          <p style="margin-top: 15px">
            <a href="/news/6/index.html"
              ><span
                style="
                  border: 1px solid #c72128;
                  border-radius: 6px;
                  color: #c72128;
                  font-size: 12px;
                  padding: 4px 10px;
                  margin-right: 10px;
                "
                >{{ zx.type3 }}</span
              ></a
            ><span style="font-size: 12px; color: #333; padding: 4px 10px"
              >{{ zx.time3 }}</span
            >
          </p>
        </div>
        <div
          style="
            width: 780px;
            height: 135px;
            background-color: #fffefe;
            margin-top: 30px;
          "
        >
          <h2 class="h3">
            <a :href="zx.a4">{{ zx.title4 }}</a>
          </h2>
          <p
            style="
              line-height: 1.875rem;
              margin-top: 10px;
              font-size: 0.875rem;
              color: #999999;
            "
          >
            {{ zx.text4 }}
          </p>
          <p style="margin-top: 15px">
            <a href=""
              ><span
                style="
                  border: 1px solid #c72128;
                  border-radius: 6px;
                  color: #c72128;
                  font-size: 12px;
                  padding: 4px 10px;
                  margin-right: 10px;
                "
                >{{ zx.type4 }}</span
              ></a
            ><span style="font-size: 12px; color: #333; padding: 4px 10px"
              >{{ zx.time4 }}</span
            >
          </p>
        </div>
      </div>
      <div
        style="
          margin-top: 30px;
          width: 400px;
          height: 385px;

          margin-left: 20px;
        "
      >
        <div
          style="
            width: 400px;
            height: 32px;
            background-image: url('https://ess.leju.com/house/photo/42-1-hvHK20oTZP4WdJXMJCKkepuVrfFlktq435siiIaDUTl8mt44lZHd4BsPMhOUsW9Jxxjucolg40xuIVZa.png');
            background-size: 120px 32px;
            background-repeat: no-repeat;
            width: 240px;
            height: 32px;
            line-height: 52px;
            font-size: 12px;
            color: #999;
            margin-bottom: 16px;
            padding-left: 140px;
          "
        >
          <span>  </span>
          <a
            href="http://zjt.hainan.gov.cn/"
            style="
              float: right;
              font-size: 12px;
              background: url(https://ess.leju.com/house/photo/42-1-QpdANKx9SZ3NiaXlSqmPB0HSmPFxEtlYMZHm9Wass0SLJycTSWhZM723e6VguFOytEMt5plVCN602uNK.png) no-repeat 30px 21px;
              background-size: 10px 10px;
              padding-right: 20px;
              background-repeat: no-repeat;
            "
            >更多</a
          >
        </div>
        <ul
          style="background-color: #eee; width: 360px; padding: 20px"
          class="u8"
        >
          <li>
            <a
              :href="sdzl.text1"
              :title="sdzl.text1"
              >{{ sdzl.text1 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a2"
              :title="sdzl.text2"
              >{{sdzl.text2 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a3"
              :title="sdzl.text3"
              >{{ sdzl.text3 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a4"
              :title="sdzl.text4"
              >{{ sdzl.text4 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a5"
              :title="sdzl.text5"
              >{{ sdzl.text5 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a6"
              :title="sdzl.text6"
              >{{ sdzl.text6 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a7"
              :title="sdzl.text7"
              >{{ sdzl.text7 }}</a
            >
          </li>
          <li>
            <a
              :href="sdzl.a8"
              :title="sdzl.text8"
              >{{ sdzl.text8 }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      style="
        width: 1200px;
        margin: auto;
        display: flex;
        height: 655px;
        position: relative;
        margin-top: 30px;
      "
    >
      <div style="width: 890px">
        <ul class="u9" style="width: 890px">
          <li style="color: #c30535; font-size: 22px">今日关注</li>
          <li>|</li>
          <a href=""><li>时讯</li></a>
          <a href=""><li>公司</li></a>
          <a href=""><li>政策</li></a>
          <a href=""><li>土地</li></a>
          <a href=""><li>市场</li></a>
          <a href=""><li>深度</li></a>
          <a href=""><li>物业</li></a>
          <a href=""><li>运营商</li></a>
          <a href=""><li>城市聚焦</li></a>
        </ul>
        <div style="width: 890px; height: 2px; background-color: #ccc"></div>

        <div
          style="width: 890px; height: 200px; display: flex; margin-top: 30px"
        >
          <div
            style="
              width: 240px;
              height: 150px;
              border-radius: 5px;
              overflow: hidden;
            "
          >
            <a :href="jrgz.a1">
              <img
                :src="jrgz.url1"
                alt=""
                style="width: 240px; height: 150px"
            /></a>
          </div>
          <div
            style="
              width: 570px;
              height: 150px;
              margin-left: 20px;
              position: relative;
            "
          >
            <h2 class="h4">
              <a :href="jrgz.a1"> {{ jrgz.title1}} </a>
            </h2>
            <p
              style="
                font-size: 12px;
                color: #a2a2a2;
                line-height: 22px;
                margin-top: 15px;
                max-height: 44px;
                overflow: hidden;
                text-align: justify;
              "
            >
             {{ jrgz.text1 }}
            </p>
            <div style="position: absolute; bottom: 0px" class="box1">
              <span>{{ jrgz.wz1 }}</span> 
            </div>
          </div>
        </div>
        <div style="width: 890px; height: 200px; display: flex">
          <div
            style="
              width: 240px;
              height: 150px;
              border-radius: 5px;
              overflow: hidden;
            "
          >
            <a :href="jrgz.a2"
              ><img
                :src="jrgz.url2"
                alt=""
                style="width: 240px; height: 150px"
            /></a>
          </div>
          <div
            style="
              width: 570px;
              height: 150px;
              margin-left: 20px;
              position: relative;
            "
          >
            <h2 class="h4">
              <a :href="jrgz.a2">{{ jrgz.title2 }}</a>
            </h2>
            <p
              style="
                font-size: 12px;
                color: #a2a2a2;
                line-height: 22px;
                margin-top: 15px;
                max-height: 44px;
                overflow: hidden;
                text-align: justify;
              "
            >
              {{ jrgz.text2 }}
            </p>
            <div style="position: absolute; bottom: 0px" class="box1">
              <span>{{ jrgz.wz2 }}</span> 
            </div>
          </div>
        </div>
        <div style="width: 890px; height: 200px; display: flex">
          <div
            style="
              width: 240px;
              height: 150px;
              border-radius: 5px;
              overflow: hidden;
            "
          >
            <a :href="jrgz.a3"
              ><img
                :src="jrgz.url3"
                alt=""
                style="width: 240px; height: 150px"
            /></a>
          </div>
          <div
            style="
              width: 570px;
              height: 150px;
              margin-left: 20px;
              position: relative;
            "
          >
            <h2 class="h4">
              <a :href="jrgz.a3">{{ jrgz.title3 }} </a>
            </h2>
            <p
              style="
                font-size: 12px;
                color: #a2a2a2;
                line-height: 22px;
                margin-top: 15px;
                max-height: 44px;
                overflow: hidden;
                text-align: justify;
              "
            >
              {{ jrgz.text3 }}
            </p>
            <div style="position: absolute; bottom: 0px" class="box1">
              <span>{{ jrgz.wz3 }}</span> 
            </div>
          </div>
        </div>
        <a href="http://zjt.hainan.gov.cn/"
          ><div
            style="
              width: 100%;
              height: 30px;
              background-color: #c72128;
              text-align: center;
              line-height: 30px;
              color: #fff;
            "
          >
            查看更多
          </div></a
        >
      </div>
      <div
        style="
          width: 282px;
          height: 469px;
          position: absolute;
          right: 0;
          top: 65px;
          border: 1px solid #ccc;
          padding: 20px;
          box-sizing: border-box;
        "
      >
        <p style="font-size: 22px; font-weight: bold">深度</p>
        <div
          style="
            width: 100%;
            height: 1px;
            background-color: #ccc;
            margin-top: 20px;
          "
        ></div>
        <ul class="u10">
          <a :href="sd.a1">
            <li style="display: flex">
              <p style="" class="up1">01</p>
              <p
                style="white-space: normal; font-size: 14px; margin-left: 10px"
                class="up2"
              >
                {{sd.text1}}
              </p>
            </li>
          </a>
          <a :href="sd.a2">
            <li style="display: flex">
              <p style="" class="up1">02</p>
              <p
                style="white-space: normal; font-size: 14px; margin-left: 10px"
                class="up2"
              >
                {{ sd.text2 }}
              </p>
            </li>
          </a>
<a :href="sd.a3">
            <li style="display: flex">
              <p style="" class="up1">03</p>
              <p
                style="white-space: normal; font-size: 14px; margin-left: 10px"
                class="up2"
              >
               {{ sd.text3 }}
              </p>
            </li>
          </a>
          <a :href="sd.a4">
            <li style="display: flex">
              <p style="" class="up1">04</p>
              <p
                style="white-space: normal; font-size: 14px; margin-left: 10px"
                class="up2"
              >
               {{sd.text4}}
              </p>
            </li>
          </a>
          <a :href="sd.a5">
            <li style="display: flex">
              <p style="" class="up1">05</p>
              <p
                style="white-space: normal; font-size: 14px; margin-left: 10px"
                class="up2"
              >
              {{sd.text5}}
              </p>
            </li>
          </a>
        </ul>
        <a href="http://zjt.hainan.gov.cn/"><div class="box2">查看全部></div></a>
      </div>
    </div>
    <div
      style="
        margin-top: 100px;
        width: 100%;
        height: 140px;
        padding: 45px 0;
        background: #c50a32
          url(http://house.china.com.cn/images/conpinducbg.jpg) no-repeat center
          top;
        background-size: 100% 100%;
        position: relative;
      "
    >
      <div style="width: 1200px; margin: auto; position: relative">
        <h1 style="font-size: 30px; color: #fff; font-weight: 300">
          海南房产网
        </h1>
        <p
          style="
            width: 600px;
            font-size: 18px;
            color: #e38b9f;
            line-height: 30px;
            text-align: justify;
            margin-top: 20px;
          "
        >
          中国网络集团 监制
        </p>
        <!-- <div
          style="position: absolute;width
    110px;height:140px;top:0;right:0"
        >
          <img
            src="http://house.china.com.cn/images/wxdichan.jpg"
            alt=""
            style="width: 110px; height: 110px"
          />
          <p
            style="
              width: 110px;
              text-align: center;
              margin-top: 10px;
              font-size: 14px;
              color: #fff;
            "
          >
            中国网地产
          </p>
        </div> -->
      </div>
    </div>

    <div style="width: 100%; height: 290px; background-color: #2a2a32">
      <div style="width: 1200px; margin: auto; position: relative">
        <ul class="u11">
          <li style="color: #c50a32; font-size: 20px">热门推荐</li>
          <!-- <li>机构报告</li>
          <li>友情链接</li> -->
        </ul>
        <div
          style="
            width: 1200px;
            height: 1px;
            border-top: 1px dashed #0b0b0e;
            margin: 30px 0;
          "
        ></div>
        <div
          style="
            width: 1200px;
            height: 1px;
            border-top: 1px dashed #0b0b0e;
            margin: 30px 0;
            position: absolute;
            top: 260px;
          "
        ></div>
        <div class="friendlyLinkPage clearfix" style="">
          <a href="http://house.china.com.cn/shixun" target="_blank">时讯</a>
          <a href="http://house.china.com.cn/gongsi" target="_blank">公司</a>
          <a href="http://house.china.com.cn/pingji" target="_blank">评级</a>
          <a href="http://house.china.com.cn/dichangu" target="_blank"
            >地产股</a
          >
          <a href="http://house.china.com.cn/yeji" target="_blank">业绩</a>
          <a href="http://house.china.com.cn/ipo" target="_blank">IPO</a>
          <a href="http://house.china.com.cn/rongzi" target="_blank">融资</a>
          <a href="http://house.china.com.cn/shichang" target="_blank"
            >市场行情</a
          >
          <a href="http://house.china.com.cn/loushi" target="_blank">楼市</a>
          <a href="http://house.china.com.cn/zhengce" target="_blank">政策</a>
          <a href="http://house.china.com.cn/baogao" target="_blank">报告</a>
          <a href="http://house.china.com.cn/loupan" target="_blank">楼盘</a>
          <a href="http://house.china.com.cn/shendu" target="_blank">深度</a>
          <a href="http://house.china.com.cn/toushi" target="_blank">透视</a>
          <a href="http://house.china.com.cn/qishilu" target="_blank">企示录</a>
          <a href="http://house.china.com.cn/iposhijie" target="_blank"
            >IPO视界</a
          >
          <a href="http://house.china.com.cn/dajia" target="_blank">大家</a>
          <a href="http://house.china.com.cn/zhishu" target="_blank"
            >领先指数</a
          >
          <a href="http://house.china.com.cn/lingxianloupan" target="_blank"
            >领先楼盘</a
          >
          <a href="http://house.china.com.cn/yuedanpinglou" target="_blank"
            >月旦评楼</a
          >
          <a href="http://house.china.com.cn/yunyingshang" target="_blank"
            >运营商</a
          >
          <a href="http://house.china.com.cn/gengxin" target="_blank"
            >城市更新</a
          >
          <a href="http://house.china.com.cn/wuye" target="_blank">物业</a>
          <a href="http://house.china.com.cn/chanye" target="_blank">产业</a>
          <a href="http://house.china.com.cn/wenlv" target="_blank">文旅</a>
          <a href="http://house.china.com.cn/shangye" target="_blank">商业</a>
          <a href="http://house.china.com.cn/dibiao" target="_blank">地标</a>
          <a href="http://house.china.com.cn/kangyang" target="_blank">康养</a>
          <a href="http://house.china.com.cn/keji" target="_blank">智慧科技</a>
          <a href="http://house.china.com.cn/caibao" target="_blank">财报</a>
          <a href="http://house.china.com.cn/jiedu" target="_blank">解读</a>
          <a href="http://house.china.com.cn/shudu" target="_blank">数读</a>
          <a href="http://house.china.com.cn/zhuanti" target="_blank">专题</a>
          <a href="http://house.china.com.cn/tushuo" target="_blank">图说</a>
          <a href="http://house.china.com.cn/caibaozhtc" target="_blank"
            >财报直通车</a
          >
          <a href="http://house.china.com.cn/jujiao" target="_blank"
            >城市聚焦</a
          >
        </div>
      </div>
    </div>

    <div class="publicFoot">
      <div class="pubFootCont">
        <div class="pubFootTop">
          <a href=""
            >关于我们</a
          >
          |<a href=""
            >主办单位</a
          >
          |<a href=""
            >权责申明</a
          >
          |<a href=""
            >联系我们</a
          >
          |<a href=""
            >广告合作</a
          >
        </div>
        <div class="pubFootBotm">
          琼ICP备2020003380号-9 | 琼公网安备 46010802000744号
海南教育网 版权所有 中国网络集团监制 技术支持：中网科技（海南）有限公司 <br />
邮箱：kf@China-kj.cn 联系电话：0898-65669506
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import { getsdgg } from '@/api/zwkj';
// import "element-ui/lib/theme-chalk/base.css";
// import Sldentify from '@/pages/siddentify/index.vue'
import Swiper from "../../components/Swiper.vue";
// import swipers from '../../components/Swipers.vue'
export default {
  name: "ShoYe",
  data() {
    return {
      show:1,
      fxgz:{},
      zxt:{},
      zx:{},
      jszx:{},
      sdzl:{},
      jrgz:{},
      sd:{},
      value:''
    };
  },
  components: {
    Swiper,
  },
  mounted() {
    this.getfxgz()
    this.getzxt()
    this.getzx()
    this.getjszx()
    this.getsdzl()
    this.getjrgz()
    this.getsd()
  },

  methods: {
    gobd(){
      window.open(`https://www.baidu.com/s?wd=${this.value}`)
    },
    async getsd(){
          const res=await this.$store.dispatch('getfcsd');
          this.sd={...res}
    },
    async getjrgz(){
          const res=await this.$store.dispatch('getfcjrgz');
          this.jrgz={...res}
    },
    async getsdzl(){
        const res=await this.$store.dispatch('getfcsdzl')
          this.sdzl={...res}
    }, 
    async getjszx(){
      const res=await this.$store.dispatch('getfcjszx')
      this.jszx={...res}
    },
    async getzx(){
        const res=await this.$store.dispatch('getfczx');
        this.zx={...res}
    },
    async getfxgz(){
       const res=await this.$store.dispatch('getfcfxgz')
       this.fxgz={...res}
    },
    async getzxt(){
          const res=await this.$store.dispatch('getfczxt')
          this.zxt={...res}
    }
  },
};
</script>
  
<style lang="css" scoped>
.utop{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  
}
.utop div{
  margin-right: 15px;
  width: 160px;
  height: 50px;
line-height: 50px;
text-align: start;
font-size: 14px;
border-bottom: 1px solid #fff;
}
.utop div:hover{
  color: #c72128;
  border-bottom: 1px solid #c72128;
}
.publicFoot {
  width: 100%;
  min-width: 1200px;
  background-color: #2a2a32;
}
.pubFootCont {
  width: 1180px;
  margin: 0 auto;
  padding: 45px 0px;
  text-align: center;
}
.pubFootTop {
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  opacity: 0.5;
}
.pubFootTop a {
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  padding: 0px 25px;
}
.pubFootBotm {
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  opacity: 0.5;
  margin-top: 5px;
}

.friendlyLinkPage {
  width: 100%;
}
.friendlyLinkCont {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px dashed #0b0b0e;
  border-bottom: 1px dashed #0b0b0e;
}
.clearfix {
  zoom: 1;
}
.friendlyLinkPage a {
  display: block;
  float: left;
  font-size: 12px;
  line-height: 32px;
  color: #a2a2a2;
  width: 120px;
  overflow: hidden;
}
.u11 {
  display: flex;
}
.u11 li {
  color: #fff;
  margin-top: 40px;
  margin-right: 100px;
  font-size: 20px;
  font-weight: bold;
}

.up2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box2 {
  width: 100%;
  height: 35px;
  line-height: 35px;
  color: #a3a2a2;
  background-color: #f8f6f7;
  margin-top: 20px;
  text-align: center;
}
.box2:hover {
  background-color: #c72128;
  color: #fff;
}
.up1 {
  width: 44px;
  height: 46px;
  font-size: 35px;
  color: #ecebeb;
}
.u10 li {
  align-items: center;
  margin-top: 20px;
}
.u10 li:hover p {
  color: #c72128;
}
.box1 span {
  font-size: 12px;
  color: #a2a2aa;
}
.box1 span:hover {
  color: #c72128;
}
.h4 a {
  font-size: 20px;
  font-size: 19px;
  /* font-weight: 500; */
}
.h4 p {
  list-style: none;
  /* background-color: pink; */
  /* 不设置高度的时候，高度由内容撑开 */
  white-space: nowrap; /* 强制文本在一行中显示 */
  overflow: hidden;
  text-overflow: ellipsis; /* 文本超出后显示省略号*/
}
.u9 {
  display: flex;
  width: 780px;
  height: 55px;
  /* background-color: green; */
  line-height: 55px;
}
.u9 li {
  font-size: 15px;
  margin-right: 30px;
  font-weight: bold;
  color: #2b2b2b;
}
.u8 li {
  width: 340px;
  padding-left: 20px;
  line-height: 24px;
  margin-bottom: 14px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.u8 li::before {
  content: "·";
  font-size: 2.25rem;
  position: absolute;
  top: 0px;
  left: 0px;
  color: rgb(201, 201, 201);
}
.u8 li a {
  white-space: normal;
}
.u8 li a:hover {
  color: #c72128;
}
.h3 a {
  font-size: 20px;
  font-weight: 500;
}
.h3 a:hover {
  color: #c72128;
}
.u7 li {
  margin-bottom: 30px;
  
}
.u7 li span{
  background-repeat: no-repeat;
}
.u7{
  padding-top:20px ;
  padding-left: 10px;
  box-sizing: border-box;
  
}
.a1 {
  font-size: 16px;
  color: #333333;
}
.a1:hover {
  color: #c72128;
}
.p1 {
  color: #9999;
  font-size: 14px;
  line-height: 1.875rem;
}
.h1 a:hover {
  color: #c72128;
}
.h1 {
  font-size: 19px;
  font-weight: 500;
  list-style: none;
  /* background-color: pink; */
  /* 不设置高度的时候，高度由内容撑开 */
  white-space: nowrap; /* 强制文本在一行中显示 */
  overflow: hidden;
  text-overflow: ellipsis; /* 文本超出后显示省略号*/
}
.search_input:focus {
  /* box-shadow: blue; */
  outline: 1px solid rgb(56, 144, 245);
  padding-left: 20px;
  box-sizing: border-box;
}
.u6 li {
  width: 110px;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  color: #9999;
}
.u6 {
  position: relative;
  display: flex;
  padding-left: 90px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center left;
  width: 1200px;
  height: 50px;
  background-size: 60px 40px;
  background-image: url("https://ess.leju.com/house/photo/42-1-VIMavviNl3iPQhP2j28mc9589MoUo02Z4yyS4oc6fChQd2vnYN0og1Jb3voywfjGj4hT7g5MDA20Tnpc.png");
}
.u5 {
  width: 285px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.u4 {
  overflow: hidden;
}
.u4 li {
  margin-top: 14px;
  position: relative;
  margin-left: 15px;
  font-size: 16px;
}
.u4 li:hover {
  color: #c72128;
}
li {
  list-style: none;
  /* background-color: pink; */
  /* 不设置高度的时候，高度由内容撑开 */
  white-space: nowrap; /* 强制文本在一行中显示 */

  text-overflow: ellipsis; /* 文本超出后显示省略号*/
}
.u4 li::before {
  content: "·";
  font-size: 2.25rem;
  position: absolute;
  top: -15px;
  left: -15px;
  color: rgb(201, 201, 201);
}
a {
  text-decoration: none;
  color: black;
}
.nav {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.nav li {
  width: 128px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  /* margin: 0 40px; */
}
.nav li a {
  color: #ffff;
  font-size: 16px;
}
.nav li:hover {
  background-image: linear-gradient(to bottom, rgb(63, 59, 59), #c72128);
}
</style>

  