import request from '@/api/request';
export const yuyueset = (data) => request({ method: 'post', data, url: 'http://www.haikoutour.cn/Api/yuyue' })
export const setjzorder = (data) => request({ method: 'post', data, url: 'http://www.haikoutour.cn/Api/JZpay' });
export const setwjorder = (data) => request({ method: 'post', data, url: 'http://www.haikoutour.cn/Api/wjpay' })

export const getfclbt = () => request({ url: 'https://www.haikoutour.cn/Api/getfclbt', method: 'get' })

export const getfcfxgz = () => request({ url: 'https://www.haikoutour.cn/Api/getfcfxgz', method: 'get' })

export const getfczxt = () => request({ url: 'https://www.haikoutour.cn/Api/getfczxt', method: 'get' })

export const getfczx = () => request({ url: 'https://www.haikoutour.cn/Api/getfczx', method: 'get' })

export const getfcjszx = () => request({ url: 'https://www.haikoutour.cn/Api/getfcjszx', method: 'get' })

export const getfcsdzl = () => request({ url: 'https://www.haikoutour.cn/Api/getfcsdzl', method: 'get' })

export const getfcjrgz = () => request({ url: 'https://www.haikoutour.cn/Api/getfcjrgz', method: 'get' })

export const getfcsd = () => request({ url: 'https://www.haikoutour.cn/Api/getfcsd', method: 'get' })