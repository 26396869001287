import { getfcsdzl, getfcsd, getfcjrgz, getfclbt, getfcfxgz, getfczxt, getfczx, getfcjszx, getsdly, getsdwy, getsdcj, getsddwjl, getsdengt, getsdeng, getsdzxt, getsdtj, getsdyw, getsdxwfb, getsdsdgd, getsdgg, getsdtitle, getsdlbt, getsdlbttext, getsdjrsd } from '@/api/zwkj/index'
const actions = {
    async getfcjrgz(context, data) {

        console.log(context, data)
        const res = await getfcjrgz()
        console.log(res)
        return res.message
    },
    async getfcsd(context, data) {

        console.log(context, data)
        const res = await getfcsd()

        return res.message
    },
    async getfcsdzl(context, data) {

        console.log(context, data)
        const res = await getfcsdzl()

        return res.message
    },
    async getfclbt(context, data) {

        console.log(context, data)
        const res = await getfclbt()

        return res.message
    },
    async getfcjszx(context, data) {

        console.log(context, data)
        const res = await getfcjszx()

        return res.message
    },


    async getfcfxgz(context, data) {

        console.log(context, data)
        const res = await getfcfxgz()

        return res.message
    },
    async getfczxt(context, data) {

        console.log(context, data)
        const res = await getfczxt()

        return res.message
    },
    async getfczx(context, data) {

        console.log(context, data)
        const res = await getfczx()

        return res.message
    },
    async getsdly(context, data) {

        console.log(context, data)
        const res = await getsdly()

        return res.message
    },
    async getsdwy(context, data) {

        console.log(context, data)
        const res = await getsdwy()

        return res.message
    },
    async getsddwjl(context, data) {

        console.log(context, data)
        const res = await getsddwjl()

        return res.message
    },
    async getsdcj(context, data) {

        console.log(context, data)
        const res = await getsdcj()

        return res.message
    },
    async getsdengt(context, data) {

        console.log(context, data)
        const res = await getsdengt()

        return res.message
    },
    async getsdeng(context, data) {

        console.log(context, data)
        const res = await getsdeng()

        return res.message
    },
    async getsdzxt(context, data) {

        console.log(context, data)
        const res = await getsdzxt()

        return res.message
    },
    async getsdtj(context, data) {

        console.log(context, data)
        const res = await getsdtj()

        return res.message
    },
    async getsdyw(context, data) {

        console.log(context, data)
        const res = await getsdyw()

        return res.message
    },
    async getsdxwfb(context, data) {

        console.log(context, data)
        const res = await getsdxwfb()

        return res.message
    },
    async getsdsdgd(context, data) {

        console.log(context, data)
        const res = await getsdsdgd()

        return res.message
    },
    async getsdjrsd(context, data) {

        console.log(context, data)
        const res = await getsdjrsd()

        return res.message
    },
    async getsdlbttext(context, data) {

        console.log(context, data)
        const res = await getsdlbttext()

        return res.message
    },

    async getsdgg(context, data) {

        console.log(context, data)
        const res = await getsdgg()

        return res.message
    },

    async getsdtitle(context, data) {

        console.log(context, data)
        const res = await getsdtitle()

        return res.message
    },
    async getsdlbt(context, data) {

        console.log(context, data)
        const res = await getsdlbt()
        console.log(res.message, 'aa')
        return res.message
    },

};
const mutations = {

};
const state = {
    searchlist: [],
    nums: 0
};
const getters = {};
export default {
    actions,
    mutations,
    state,
    getters
}